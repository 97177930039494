/* eslint-disable @typescript-eslint/no-explicit-any */
import { ImageFragment } from '@/__generated__/graphql';
import {
  deleteUndefined,
  firstNonNullable,
  makeNonNullableArray,
  pickFromUnion,
  toString,
} from '@liquorice/allsorts-craftcms-nextjs';

export type ImageTransform = {
  url: string;
  height: number;
  width: number;
};

export interface ImageEntry {
  id?: string;
  noCrop?: boolean;
  mimeType: string;
  src: string;
  alt?: string;
  title?: string;
  caption?: string;
  height?: number;
  width?: number;
  srcSet?: string;
}

export const parseImage = (data: MaybeArrayOf<ImageFragment>): ImageEntry | null => {
  const image = firstNonNullable(data);

  if (!image || !image.url) return null;

  const { id, alt, title, url: src, height, width, mimeType } = pickFromUnion(image, []);

  const imageEntry: ImageEntry = {
    id,
    mimeType: toString(mimeType),
    src,
    height,
    width,
    alt,
    title,
    // caption,
  };

  return deleteUndefined(imageEntry);
};

export const parseImageMany = (maybeImage: MaybeArrayOf<ImageFragment>) => {
  const imageArr = makeNonNullableArray(maybeImage);
  return makeNonNullableArray(imageArr.map(parseImage));
};

export const isSvg = (image?: Maybe<ImageEntry>) => {
  return image?.mimeType === 'image/svg+xml';
};
